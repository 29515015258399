.stay-booker__input {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0 14px;
    box-sizing: border-box;
    transition: border-color 0.2s ease-in-out;
    height: 45px !important;
}

.stay-booker__input:focus-within {
    border-color: #3f51b5;
    /* Same as Material-UI's primary color */
    box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2);
}

.stay-booker__input input {
    border: none;
    outline: none;
}

.input-spinner-custom {
    width: 80px !important;
    /* Set the desired width */
}