    /* Autocomplete.css */
    .autocomplete-input {
      width: 100%;
      min-width: 250px;
      padding: 10px;
      font-size: 16px;
      border-radius: 4px;
      border: 1px solid #ccc;
      color: black;
    }

    .autocomplete-item {
      padding: 10px;
      cursor: pointer;
      color: black;
    }

    .autocomplete-item.highlighted {
      background-color: #f0f0f0;
      color: black;
    }

    .custom-border {
      border: 1px solid #ced4da;
      border-radius: 4px;
      padding: 0 14px;
      box-sizing: border-box;
      transition: border-color 0.2s ease-in-out;
    }

    .custom-border:focus-within {
      border-color: #3f51b5;
      /* Same as Material-UI's primary color */
      box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2);
    }

    .custom-border input {
      height: 40px;
      border: none;
      outline: none;
    }